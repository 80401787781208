import moment from 'moment';
import _ from 'lodash';
import { Promise } from 'bluebird';

import { mediaService, roomTypesService } from '../../services/homii-services';
import {
    setIsLoadingAction,
    setRoomTypesAction,
    setSelectedRoomTypeAction,
    setSelectedBuildingRoomTypesAction,
    setSelectedBuildingRoomTypesSummaryAction,
} from './room-types.reducer';

export const ArrangementTypes = Object.freeze({ PRIVATE: 'Private', SHARED: 'Shared' });
const RenewalDuration = Object.freeze({ MONTHLY: 1, DAILY: 2 });

function prepareRoomType(room, building, type) {
    const { name, address, amenities, details } = building;
    const roomWithBuildingProps = room;
    const { documents, id } = roomWithBuildingProps;
    const { featuredAmenities } = roomWithBuildingProps;
    const allDocuments = _.concat(documents, featuredAmenities);
    const {
        roomImages,
        floorplanImages,
        featuredAmenityImages,
    } = mediaService.generateEntityImageSources(allDocuments, id, 'roomType');

    roomWithBuildingProps.buildingName = name;
    roomWithBuildingProps.buildingAddress = address;
    roomWithBuildingProps.buildingAmenities = amenities;
    roomWithBuildingProps.buildingDescription = details;
    roomWithBuildingProps.arrangementType = type;
    roomWithBuildingProps.images = _.get(roomImages, 'imageSources');
    roomWithBuildingProps.thumbnails = _.get(roomImages, 'thumbnailSources');
    roomWithBuildingProps.floorplanImages = _.get(floorplanImages, 'floorplanSources');
    roomWithBuildingProps.floorplanThumbnails = _.get(floorplanImages, 'floorplanThumbnailSources');
    roomWithBuildingProps.featuredAmenityImages = _.get(
        featuredAmenityImages,
        'featuredAmenitySources',
    );
    roomWithBuildingProps.featuredAmenityThumbnails = _.get(
        featuredAmenityImages,
        'featuredAmenityThumbnailSources',
    );

    delete roomWithBuildingProps.documents;
    return roomWithBuildingProps;
}

function prepareRoomTypesForBuilding(room, building, type) {
    const { name, address, amenities, details } = building;
    const roomWithBuildingProps = room;
    const { documents } = roomWithBuildingProps.ids[0];
    const id = roomWithBuildingProps.ids[0].roomTypeId;
    const { featuredAmenities } = roomWithBuildingProps;
    const allDocuments = _.concat(documents, featuredAmenities);
    const {
        roomImages,
        floorplanImages,
        featuredAmenityImages,
    } = mediaService.generateEntityImageSources(allDocuments, id, 'roomType');

    roomWithBuildingProps.buildingName = name;
    roomWithBuildingProps.buildingAddress = address;
    roomWithBuildingProps.buildingAmenities = amenities;
    roomWithBuildingProps.buildingDescription = details;
    roomWithBuildingProps.arrangementType = type;
    roomWithBuildingProps.images = _.get(roomImages, 'imageSources');
    roomWithBuildingProps.thumbnails = _.get(roomImages, 'thumbnailSources');
    roomWithBuildingProps.floorplanImages = _.get(floorplanImages, 'floorplanSources');
    roomWithBuildingProps.floorplanThumbnails = _.get(floorplanImages, 'floorplanThumbnailSources');
    roomWithBuildingProps.featuredAmenityImages = _.get(
        featuredAmenityImages,
        'featuredAmenitySources',
    );
    roomWithBuildingProps.featuredAmenityThumbnails = _.get(
        featuredAmenityImages,
        'featuredAmenityThumbnailSources',
    );

    delete roomWithBuildingProps.documents;
    return roomWithBuildingProps;
}

async function prepareRoomTypes(buildings) {
    const roomTypes = {};
    _.forEach(buildings, (building) => {
        const { privateRoomTypes, sharedRoomTypes } = building;
        _.forEach(privateRoomTypes, async (roomType) => {
            roomTypes[roomType.id] = await prepareRoomType(
                roomType,
                building,
                ArrangementTypes.PRIVATE,
            );
        });
        _.forEach(sharedRoomTypes, async (roomType) => {
            roomTypes[roomType.id] = await prepareRoomType(
                roomType,
                building,
                ArrangementTypes.SHARED,
            );
        });
    });

    return roomTypes;
}

export function searchAvailabilityForCity() {
    return async (dispatch, getState) => {
        const { selectedCity } = getState().cityReducer;
        const { selectedTerm, dailyStartDate, dailyEndDate } = getState().roomTypesReducer;

        let startDate;
        let endDate;
        if (selectedTerm === 'Daily') {
            startDate = moment(dailyStartDate).format('YYYY-MM-DD');
            endDate = moment(dailyEndDate).format('YYYY-MM-DD');
        } else {
            startDate = moment().startOf('month').format('YYYY-MM-DD');
            endDate = moment().add(1, 'months').startOf('month').format('YYYY-MM-DD');
        }

        try {
            const buildingsWithRoomTypes = await roomTypesService.searchAvailable(
                _.get(selectedCity, 'id'),
                startDate,
                endDate,
            );
            await dispatch(searchAvailableByCityOrCoordinates(buildingsWithRoomTypes));
        } catch (error) {
            // eslint-disable-next-line no-console
            console.warn(`Failed to fetch city availability: ${error.message}`);
        }
    };
}

export function searchAvailable(type, lat, long) {
    return async (dispatch) => {
        dispatch(setIsLoadingAction(true));
        try {
            if (type === 'coordinates') {
                await dispatch(searchAvailableByCoordinates(lat, long));
            } else {
                await dispatch(searchAvailabilityForCity());
            }
        } finally {
            dispatch(setIsLoadingAction(false));
        }
    };
}

export function getRoomTypeById(roomTypeId) {
    return (dispatch, getState) => {
        const { buildings } = getState().buildingReducer;
        dispatch(setIsLoadingAction(true));
        return roomTypesService
            .getRoomTypeById(roomTypeId)
            .then(async (roomType) => {
                const building = buildings[roomType.buildingId];
                const arrangementType = roomType.sharedLivingSpace
                    ? ArrangementTypes.SHARED
                    : ArrangementTypes.PRIVATE;
                const processedRoomType = prepareRoomType(roomType, building, arrangementType);
                dispatch(setSelectedRoomTypeAction(processedRoomType));
            })
            .finally(() => {
                dispatch(setIsLoadingAction(false));
            });
    };
}

export function getAllRoomTypesByCity() {
    return async (dispatch, getState) => {
        dispatch(setIsLoadingAction(true));
        const cities = _.map(getState().cityReducer.cities, (city) => city);
        const allRoomTypes = [];

        await Promise.map(cities, async (_city) => {
            const city = _city;
            const roomTypes = await roomTypesService.getRoomTypeByCityId(city.id);

            _.forEach(roomTypes.privateRoomTypes, (privateRoomType) => {
                allRoomTypes.push(privateRoomType);
            });
            _.forEach(roomTypes.sharedRoomTypes, (sharedRoomType) => {
                allRoomTypes.push(sharedRoomType);
            });
        });

        dispatch(setRoomTypesAction(allRoomTypes));
    };
}

function setAvailabilityForRoomType(availability, _roomType) {
    const roomType = _roomType;
    roomType.soonestAvailable = _.get(availability, 'soonestAvailable');
    roomType.numberAvailable = _.get(availability, 'numberAvailable');
    roomType.acceptingProvisionalBookings = _.get(availability, 'acceptingProvisionalBookings');
    roomType.numberAvailableForMales = _.get(
        availability,
        'sharedRoomSplit.numberAvailableForMales',
    );
    roomType.numberAvailableForFemales = _.get(
        availability,
        'sharedRoomSplit.numberAvailableForFemales',
    );

    return roomType;
}

export function checkRoomTypeAvailability(_startDate, _endDate) {
    return async (dispatch, getState) => {
        const { selectedRoomType } = getState().roomTypesReducer;
        const isDaily = _.get(selectedRoomType, 'renewalDuration') === RenewalDuration.DAILY;
        let startDate = moment(_startDate).format('YYYY-MM-DD');
        let endDate = moment(_endDate).format('YYYY-MM-DD');

        dispatch(setIsLoadingAction(true));
        try {
            if (isDaily) {
                if (startDate && endDate) {
                    const availability = await roomTypesService.getRoomTypeAvailability(
                        _.get(selectedRoomType, 'id'),
                        startDate,
                        endDate,
                    );
                    const roomTypeWithAvailability = setAvailabilityForRoomType(
                        availability,
                        selectedRoomType,
                    );
                    dispatch(setSelectedRoomTypeAction(roomTypeWithAvailability));
                }

                return;
            }

            startDate = moment().startOf('month').format('YYYY-MM-DD');
            endDate = moment().add(1, 'months').startOf('month').format('YYYY-MM-DD');

            const availability = await roomTypesService.getRoomTypeAvailability(
                _.get(selectedRoomType, 'id'),
                startDate,
                endDate,
            );

            const roomTypeWithAvailability = setAvailabilityForRoomType(
                availability,
                selectedRoomType,
            );
            dispatch(setSelectedRoomTypeAction(roomTypeWithAvailability));
        } finally {
            dispatch(setIsLoadingAction(false));
        }
    };
}

export function getAllRoomTypesByBuilding() {
    return async (dispatch, getState) => {
        dispatch(setIsLoadingAction(true));
        const { selectedBuilding } = getState().buildingReducer;
        const allRoomTypes = [];
        const roomTypes = await roomTypesService.getRoomTypeByBuildingId(selectedBuilding.id);

        _.forEach(roomTypes.privateRoomTypes, (privateRoomType) => {
            allRoomTypes.push(privateRoomType);
        });
        _.forEach(roomTypes.sharedRoomTypes, (sharedRoomType) => {
            allRoomTypes.push(sharedRoomType);
        });

        dispatch(setRoomTypesAction(allRoomTypes));
    };
}

export function checkRoomTypeAvailabilityPerBuilding() {
    return async (dispatch, getState) => {
        const { selectedBuilding } = getState().buildingReducer;

        dispatch(setIsLoadingAction(true));
        try {
            const startDate = moment().startOf('month').format('YYYY-MM-DD');
            const endDate = moment().add(1, 'months').startOf('month').format('YYYY-MM-DD');
            const allRoomTypes = [];
            const availability = await roomTypesService.getRoomTypeSearchAvailableByBuilding(
                _.get(selectedBuilding, 'id'),
                startDate,
                endDate,
            );

            _.forEach(availability.privateRoomTypes, async (privateRoomType) => {
                const roomType = await prepareRoomTypesForBuilding(
                    privateRoomType,
                    selectedBuilding,
                    ArrangementTypes.PRIVATE,
                );
                allRoomTypes.push(roomType);
            });
            _.forEach(availability.sharedRoomTypes, async (sharedRoomType) => {
                const roomType = await prepareRoomTypesForBuilding(
                    sharedRoomType,
                    selectedBuilding,
                    ArrangementTypes.SHARED,
                );

                allRoomTypes.push(roomType);
            });

            dispatch(setSelectedBuildingRoomTypesAction(allRoomTypes));
        } finally {
            dispatch(setIsLoadingAction(false));
        }
    };
}

export async function searchAvailableByCoordinates(lat, long) {
    return async (dispatch, getState) => {
        const { coordinates } = getState().geolocationReducer;
        const { selectedTerm, dailyStartDate, dailyEndDate } = getState().roomTypesReducer;
        const latitude = coordinates.latitude || lat;
        const longitude = coordinates.longitude || long;

        let startDate;
        let endDate;
        if (selectedTerm === 'Daily') {
            startDate = moment(dailyStartDate).format('YYYY-MM-DD');
            endDate = moment(dailyEndDate).format('YYYY-MM-DD');
        } else {
            startDate = moment().startOf('month').format('YYYY-MM-DD');
            endDate = moment().add(1, 'months').startOf('month').format('YYYY-MM-DD');
        }

        // eslint-disable-next-line max-len
        const buildingsWithRoomTypes = await roomTypesService.getRoomTypeSearchAvailableByCoordinates(
            latitude,
            longitude,
            startDate,
            endDate,
        );

        await dispatch(searchAvailableByCityOrCoordinates(buildingsWithRoomTypes));
    };
}

async function searchAvailableByCityOrCoordinates(buildingsWithRoomTypes) {
    return async (dispatch) => {
        const roomTypeObjects = await prepareRoomTypes(buildingsWithRoomTypes.buildings);
        const sharedRoomTypes = [];
        let roomTypes = _.map(roomTypeObjects, (roomType) => roomType);
        _.map(roomTypes, (roomType) => {
            const arrangementType = _.get(roomType, 'arrangementType');

            if (arrangementType === 'Shared' && !_.get(roomType, 'gender')) {
                const maleRoomType = {
                    ...roomType,
                    gender: 'male',
                    numberAvailable: _.get(roomType, 'numberAvailableForMales'),
                };
                const femaleRoomType = {
                    ...roomType,
                    gender: 'female',
                    numberAvailable: _.get(roomType, 'numberAvailableForFemales'),
                    id: `${roomType.id}___female`,
                };
                sharedRoomTypes.push(maleRoomType, femaleRoomType);
            }
        });
        roomTypes = _.concat(roomTypes, sharedRoomTypes);

        const updatedRoomTypeObjects = {};

        _.forEach(roomTypes, (roomType) => {
            updatedRoomTypeObjects[roomType.id] = roomType;
        });

        dispatch(setRoomTypesAction(updatedRoomTypeObjects));
    };
}

export function getAllRoomTypesByPortfolioId(portfolioId) {
    return async (dispatch) => {
        dispatch(setIsLoadingAction(true));
        const allRoomTypes = [];
        const roomTypes = await roomTypesService.getRoomTypeByPortofolioId(portfolioId);

        _.forEach(roomTypes.privateRoomTypes, (privateRoomType) => {
            allRoomTypes.push(privateRoomType);
        });
        _.forEach(roomTypes.sharedRoomTypes, (sharedRoomType) => {
            allRoomTypes.push(sharedRoomType);
        });

        dispatch(setRoomTypesAction(allRoomTypes));
    };
}

export function getRoomTypeSearchAvailableByFilter() {
    return async (dispatch, getState) => {
        const { selectedCity } = getState().cityReducer;
        const { selectedTerm, dailyStartDate, dailyEndDate } = getState().roomTypesReducer;
        const { portfolioId } = getState().portfolioReducer;

        let startDate;
        let endDate;
        if (selectedTerm === 'Daily') {
            startDate = moment(dailyStartDate).format('YYYY-MM-DD');
            endDate = moment(dailyEndDate).format('YYYY-MM-DD');
        } else {
            startDate = moment().startOf('month').format('YYYY-MM-DD');
            endDate = moment().add(1, 'months').startOf('month').format('YYYY-MM-DD');
        }

        const buildingsWithRoomTypes = await roomTypesService.getRoomTypeSearchAvailableByFilter(
            selectedCity.id,
            startDate,
            endDate,
            portfolioId,
        );

        await dispatch(searchAvailableByCityOrCoordinates(buildingsWithRoomTypes));
    };
}

export function getRoomTypeSummaryAvailabilityPerBuilding() {
    return async (dispatch, getState) => {
        const { selectedBuilding } = getState().buildingReducer;

        dispatch(setIsLoadingAction(true));
        try {
            const summaries = [];
            const summaryResults = await roomTypesService.getRoomTypeSummaryAvailableByBuilding(
                _.get(selectedBuilding, 'id'),
            );
            _.forEach(summaryResults.summary, async (summary) => {
                summaries.push(summary);
            });
            dispatch(setSelectedBuildingRoomTypesSummaryAction(summaries));
        } finally {
            dispatch(setIsLoadingAction(false));
        }
    };
}

export function getRoomTypeByRoomTypeId(roomTypeId) {
    return roomTypesService.getRoomTypeByRoomTypeId(roomTypeId);
}
