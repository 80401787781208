import contactUsUrls from './contact-us-service-urls';
import networkService from '../network-service/network.service';

function submitGeneralInquiry(firstName, lastName, email, phoneNumber, inquiry) {
    const url = contactUsUrls.generalInquiryUrl();
    const data = { firstName, lastName, phoneNumber, email, inquiry };
    return networkService.makePost(url, data);
}

function submitBookViewing(firstName, lastName, email, phoneNumber, dates, roomType, budget, city) {
    const url = contactUsUrls.bookViewingUrl();
    const data = { firstName, lastName, phoneNumber, email, dates, roomType, budget, city };
    return networkService.makePost(url, data);
}

function submitJoinNewsletter(email) {
    const url = contactUsUrls.joinNewsletterUrl();
    const data = { email };
    return networkService.makePost(url, data);
}

function submitRegisterToOclub(firstName, lastName, email, phone, age, goal) {
    const url = contactUsUrls.registerOClubUrl();
    const data = { firstName, lastName, email, phone, age, goal };
    return networkService.makePost(url, data);
}

function submitCommercialRental(
    title,
    name,
    emailAddress,
    contactNumber,
    company,
    typeOfBusiness,
    sizeOfRental,
) {
    const url = contactUsUrls.commercialRentalUrl();
    const data = {
        title,
        name,
        emailAddress,
        contactNumber,
        company,
        typeOfBusiness,
        sizeOfRental,
    };
    return networkService.makePost(url, data);
}

function submitInvesting(title, name, emailAddress, contactNumber, company) {
    const url = contactUsUrls.investingUrl();
    const data = { title, name, emailAddress, contactNumber, company };
    return networkService.makePost(url, data);
}

function submitPartnersAndLandlords(
    title,
    name,
    emailAddress,
    contactNumber,
    company,
    numberOfBuildings,
    city,
    country,
) {
    const url = contactUsUrls.partnersAndLandlordsUrl();
    const data = {
        title,
        name,
        emailAddress,
        contactNumber,
        company,
        numberOfBuildings,
        city,
        country,
    };
    return networkService.makePost(url, data);
}

function submitCorporateBookings(
    title,
    name,
    emailAddress,
    contactNumber,
    company,
    numberOfGuests,
    startDate,
    endDate,
    city,
) {
    const url = contactUsUrls.corporateBookingsUrl();
    const data = {
        title,
        name,
        emailAddress,
        contactNumber,
        company,
        numberOfGuests,
        startDate,
        endDate,
        city,
    };
    return networkService.makePost(url, data);
}

function submitLeadFromMarketingCampaign(
    firstName,
    lastName,
    phoneNumber,
    email,
    campaignName,
    roomType,
    furnishing,
    estimatedPrice,
    size,
) {
    const url = contactUsUrls.leadFromMarketingCampaignUrl();
    const data = {
        firstName,
        lastName,
        phoneNumber,
        email,
        campaignName,
        roomType,
        furnishing,
        estimatedPrice,
        size,
    };

    return networkService.makePost(url, data);
}

function submitLeadFromSalesPerson(
    firstName,
    lastName,
    phoneNumber,
    email,
    _salesPerson,
    building,
    roomType,
) {
    const url = contactUsUrls.leadFromSalesPersonUrl();
    const salesPerson = `${building}_${_salesPerson}`;
    const data = {
        firstName,
        lastName,
        phoneNumber,
        email,
        salesPerson,
        building,
        roomType,
    };
    return networkService.makePost(url, data);
}

function submitLeadFromRequestCallBack(
    firstName,
    lastName,
    phoneNumber,
    email,
    building,
    roomType,
) {
    const url = contactUsUrls.leadFromRequestCallBackUrl();
    const data = {
        firstName,
        lastName,
        phoneNumber,
        email,
        building,
        roomType,
    };
    return networkService.makePost(url, data);
}

function getMarketingCampaignDataByName(campaignName) {
    const url = contactUsUrls.getMarketingCampaignDataByNameUrl(campaignName);
    return networkService.makeGet(url);
}

export default {
    submitGeneralInquiry,
    submitBookViewing,
    submitJoinNewsletter,
    submitRegisterToOclub,
    submitCommercialRental,
    submitInvesting,
    submitPartnersAndLandlords,
    submitCorporateBookings,
    submitLeadFromMarketingCampaign,
    submitLeadFromSalesPerson,
    getMarketingCampaignDataByName,
    submitLeadFromRequestCallBack,
};
