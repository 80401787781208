import _ from 'lodash';

import { contactUsService, eventService } from '../../services/homii-services';

export async function subscribeToNewsletter(email) {
    try {
        await contactUsService.submitJoinNewsletter(email);
        eventService.logCustomEvent('successful_subscribe_to_newsletter', {
            description: 'Successfuly subscribed to newsletter',
            email,
        });
    } catch (error) {
        throw Error(
            _.get(
                error,
                'response.data.message',
                'An error occurred while trying to subscribe to newsletter.',
            ),
        );
    }
}

export async function registerToOclub(firstName, lastName, email, phone, age, goal) {
    try {
        await contactUsService.submitRegisterToOclub(firstName, lastName, email, phone, age, goal);
        eventService.logCustomEvent('successful_register_to_oclub', {
            description: 'Successfuly registered to O-Club',
            firstName,
            lastName,
            email,
            phone,
            age,
            goal,
        });
    } catch (error) {
        throw Error(
            _.get(
                error,
                'response.data.message',
                'An error occurred while trying to register to O-Club.',
            ),
        );
    }
}

export async function submitGeneralInquiry(firstName, lastName, email, phoneNumber, inquiry) {
    try {
        await contactUsService.submitGeneralInquiry(
            firstName,
            lastName,
            email,
            phoneNumber,
            inquiry,
        );
        eventService.logCustomEvent('successful_general_inquiry', {
            description: 'Successfuly submitted a general inquiry',
            firstName,
            lastName,
            email,
            phoneNumber,
            inquiry,
        });
    } catch (error) {
        throw Error(
            _.get(
                error,
                'response.data.message',
                'An error occurred while trying to submit your inquiry.',
            ),
        );
    }
}

export async function submitBookViewing(
    firstName,
    lastName,
    email,
    phoneNumber,
    dates,
    roomType,
    budget,
    city,
) {
    try {
        await contactUsService.submitBookViewing(
            firstName,
            lastName,
            email,
            phoneNumber,
            [dates],
            roomType,
            budget,
            city,
        );
        eventService.logCustomEvent('successful_book_a_viewing', {
            description: 'Successfuly booked a viewing',
            firstName,
            lastName,
            email,
            phoneNumber,
            dates,
            roomType,
            budget,
            city,
        });
    } catch (error) {
        throw Error(
            _.get(
                error,
                'response.data.message',
                'An error occurred while trying to submit your data.',
            ),
        );
    }
}

export async function submitCommercialRental(
    title,
    name,
    emailAddress,
    contactNumber,
    company,
    typeOfBusiness,
    sizeOfRental,
) {
    try {
        await contactUsService.submitCommercialRental(
            title,
            name,
            emailAddress,
            contactNumber,
            company,
            typeOfBusiness,
            sizeOfRental,
        );
        eventService.logCustomEvent('successful_commercial_rental', {
            description: 'Successfuly submitted commercial rental',
            title,
            name,
            emailAddress,
            contactNumber,
            company,
            typeOfBusiness,
            sizeOfRental,
        });
    } catch (error) {
        throw Error(
            _.get(
                error,
                'response.data.message',
                'An error occurred while trying to submit your data.',
            ),
        );
    }
}

export async function submitInvesting(title, name, emailAddress, contactNumber, company) {
    try {
        await contactUsService.submitInvesting(title, name, emailAddress, contactNumber, company);
        eventService.logCustomEvent('successful_investing', {
            description: 'Successfuly submitted investing',
            title,
            name,
            emailAddress,
            contactNumber,
            company,
        });
    } catch (error) {
        throw Error(
            _.get(
                error,
                'response.data.message',
                'An error occurred while trying to submit your data.',
            ),
        );
    }
}

export async function submitPartnersAndLandlords(
    title,
    name,
    emailAddress,
    contactNumber,
    company,
    numberOfBuildings,
    city,
    country,
) {
    try {
        await contactUsService.submitPartnersAndLandlords(
            title,
            name,
            emailAddress,
            contactNumber,
            company,
            numberOfBuildings,
            city,
            country,
        );
        eventService.logCustomEvent('successful_partners_and_landlords', {
            description: 'Successfuly submitted partners and landlords',
            title,
            name,
            emailAddress,
            contactNumber,
            company,
            numberOfBuildings,
            city,
            country,
        });
    } catch (error) {
        throw Error(
            _.get(
                error,
                'response.data.message',
                'An error occurred while trying to submit your data.',
            ),
        );
    }
}

export async function submitCorporateBookings(
    title,
    name,
    emailAddress,
    contactNumber,
    company,
    numberOfGuests,
    startDate,
    endDate,
    city,
) {
    try {
        await contactUsService.submitCorporateBookings(
            title,
            name,
            emailAddress,
            contactNumber,
            company,
            numberOfGuests,
            startDate,
            endDate,
            city,
        );
        eventService.logCustomEvent('successful_corporate_bookings', {
            description: 'Successfuly submitted corporate bookings',
            title,
            name,
            emailAddress,
            contactNumber,
            company,
            numberOfGuests,
            startDate,
            endDate,
            city,
        });
    } catch (error) {
        throw Error(
            _.get(
                error,
                'response.data.message',
                'An error occurred while trying to submit your data.',
            ),
        );
    }
}

export async function submitLeadFromMarketingCampaign(
    firstName,
    lastName,
    phoneNumber,
    email,
    campaignName,
    roomType,
    furnishing,
    estimatedPrice,
    size,
) {
    try {
        await contactUsService.submitLeadFromMarketingCampaign(
            firstName,
            lastName,
            phoneNumber,
            email,
            campaignName,
            roomType,
            furnishing,
            estimatedPrice,
            size,
        );
        eventService.logCustomEvent('completed_marketing_lead_form', {
            description: `${campaignName}: Submitted marketing campagin form`,
            firstName,
            lastName,
            phoneNumber,
            email,
            estimatedPrice,
            size,
            campaignName,
        });
    } catch (error) {
        throw Error(
            _.get(
                error,
                'response.data.message',
                'An error occurred while trying to submit your data.',
            ),
        );
    }
}

export async function submitLeadFromSalesPerson(
    firstName,
    lastName,
    phoneNumber,
    email,
    salesPerson,
    building,
    roomType,
) {
    try {
        await contactUsService.submitLeadFromSalesPerson(
            firstName,
            lastName,
            phoneNumber,
            email,
            salesPerson,
            building,
            roomType,
        );

        eventService.logCustomEvent('successful_lead_sales_person', {
            description: 'Successfully retrieved user details from lead gen sales person form',
            firstName,
            lastName,
            phoneNumber,
            email,
            salesPerson,
            building,
            roomType,
        });
    } catch (error) {
        throw Error(
            _.get(
                error,
                'response.data.message',
                'An error occurred while trying to submit your data.',
            ),
        );
    }
}

export async function submitLeadFromRequestCallBack(
    firstName,
    lastName,
    phoneNumber,
    email,
    building,
    roomType,
) {
    try {
        await contactUsService.submitLeadFromRequestCallBack(
            firstName,
            lastName,
            phoneNumber,
            email,
            building,
            roomType,
        );

        eventService.logCustomEvent('successful_lead_request_call_back', {
            description: 'Successfully retrieved user details from lead gen request call back form',
            firstName,
            lastName,
            phoneNumber,
            email,
            building,
            roomType,
        });
    } catch (error) {
        throw Error(
            _.get(
                error,
                'response.data.message',
                'An error occurred while trying to submit your data.',
            ),
        );
    }
}
